import { createApp } from "vue";
import { router } from "./router/router";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import VueTippy from "vue-tippy";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import App from "@/views/App.vue";
import InlineSvg from "@/components/shared/InlineSvg.vue";
import HeaderImage from "@/components/pre-api-page/HeaderImage.vue";
import RichTextBlock from "@/components/pre-api-page/RichTextBlock.vue";
import ChecklistGroup from "@/components/pre-api-page/ChecklistGroup.vue";
import EmergencyBanner from "@/components/pre-api-page/EmergencyBanner.vue";
import AccordionGroup from "@/components/pre-api-page/AccordionGroup.vue";
import AccordionItem from "@/components/pre-api-page/AccordionItem.vue";
import "tippy.js/dist/tippy.css";
import "./index.css";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://787dc555f31f088fb5d55f754043ec2a@o135804.ingest.us.sentry.io/4507429947703296",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://hc-frontend.cmsstaging2.image-plus.co.uk",
    /^https:\/\/hc-frontend\.cmsstaging2\.image-plus\.co\.uk/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(createPinia().use(piniaPluginPersistedState));
app.use(router);
app.use(VueTippy, {
  directive: "tippy", // => v-tippy
  component: "tippy", // => <tippy/>
  componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
  defaultProps: {
    allowHTML: true,
    theme: "hc",
  }, // => Global default options * see all props
});
app.use(PrimeVue, {
  unstyled: true,
});
app.use(ConfirmationService);
app.use(ToastService);
app.component("InlineSvg", InlineSvg);
app.component("HeaderImage", HeaderImage);
app.component("RichTextBlock", RichTextBlock);
app.component("ChecklistGroup", ChecklistGroup);
app.component("EmergencyBanner", EmergencyBanner);
app.component("AccordionGroup", AccordionGroup);
app.component("AccordionItem", AccordionItem);
app.mount("#app");
