import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd"
    }, [
      _createElementVNode("circle", {
        cx: "12",
        cy: "12",
        r: "11",
        stroke: "currentColor",
        "stroke-width": "2"
      }),
      _createElementVNode("text", {
        fill: "currentColor",
        "font-family": "TimesNewRomanPS-BoldItalicMT, Times New Roman",
        "font-size": "17",
        "font-style": "italic",
        "font-weight": "bold"
      }, [
        _createElementVNode("tspan", {
          x: "10",
          y: "18"
        }, " i ")
      ])
    ], -1)
  ])))
}
export default { render: render }