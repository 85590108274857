import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 4 6 8.58V7H4v3.11L1 12.4l1.21 1.59L4 12.62V22h16v-9.38l1.79 1.36L23 12.4zm6 16h-5v-4h-2v4H6v-8.9l6-4.58 6 4.58zM10 2c0 1.66-1.34 3-3 3-.55 0-1 .45-1 1H4c0-1.66 1.34-3 3-3 .55 0 1-.45 1-1z"
    }, null, -1)
  ])))
}
export default { render: render }