import { FormQuestionTypes } from "@/types/FormQuestionTypes";
import { FormIdType, PlotIdType } from "@/types/FormTypes";
import { defineAsyncComponent } from "vue";
import { useUserStore } from "@/stores/userStore";

// Helper functions to create FormId and PlotId
export const createFormId = (id: string): FormIdType => id as FormIdType;
export const createPlotId = (id: string): PlotIdType => id as PlotIdType;

export const getFormFieldComponent = (field: FormQuestionTypes) => {
  const components: { component: any; allowedValues: string[] }[] = [
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldHeader.vue"),
      ),
      allowedValues: ["header"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldNumber.vue"),
      ),
      allowedValues: ["number"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldEmail.vue"),
      ),
      allowedValues: ["email"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldPhone.vue"),
      ),
      allowedValues: ["phone"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldDropdown.vue"),
      ),
      allowedValues: ["dropdown"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldBoolean.vue"),
      ),
      allowedValues: ["boolean"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldNullableBoolean.vue"),
      ),
      allowedValues: ["nullable_boolean"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldTextarea.vue"),
      ),
      allowedValues: ["free_text"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldNumberStepper.vue"),
      ),
      allowedValues: ["stepper"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldRadios.vue"),
      ),
      allowedValues: ["checkbox"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldCheckboxGroup.vue"),
      ),
      allowedValues: ["checkbox_multiple"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldRangeSlider.vue"),
      ),
      allowedValues: ["slider"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldDate.vue"),
      ),
      allowedValues: ["date"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldIssuesGroup.vue"),
      ),
      allowedValues: ["issues", "issue_image"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldUpload.vue"),
      ),
      allowedValues: ["photo"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldSignature.vue"),
      ),
      allowedValues: ["signature"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldInformation.vue"),
      ),
      allowedValues: ["information"],
    },
    {
      component: defineAsyncComponent(
        () => import("@/components/forms/fields/FieldFileBoolean.vue"),
      ),
      allowedValues: ["file_boolean"],
    },
  ];

  const matched = components.find((c) => c.allowedValues.includes(field.type));

  if (matched) {
    return matched.component;
  }

  // return "";

  return defineAsyncComponent(
    () => import("@/components/forms/fields/FieldHeader.vue"),
  );
};

export const setupField = (field: FormQuestionTypes) => {
  const user = useUserStore();

  if (!user.hasRole(field.readable_by)) return;

  if (field.settings && "required" in field.settings) {
    field.settings.required =
      field.settings.required && user.hasRole(field.completable_by)
        ? field.settings.required && user.hasRole(field.completable_by)
        : false;
  }

  if (!user.hasRole(field.completable_by)) {
    field.settings.disabled = true;
  }

  return field;
};
