import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M19.4 10c-.7-3.4-3.7-6-7.4-6-2.9 0-5.4 1.6-6.6 4-3.1.4-5.4 2.9-5.4 6 0 3.3 2.7 6 6 6h13c2.8 0 5-2.2 5-5 0-2.6-2.1-4.8-4.6-5M14 13v4h-4v-4H7l5-5 5 5z"
    }, null, -1)
  ])))
}
export default { render: render }