<script setup lang="ts">
import AppMenuItem from "@/components/app-menu/AppMenuItem.vue";
import { useAppStore } from "@/stores/appStore";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";

const authStore = useAuthStore();
const appStore = useAppStore();
const userStore = useUserStore();
</script>

<template>
  <div
    class="menu__fixed w-screen bg-white text-primary shadow-app-menu lg:w-auto lg:bg-inherit lg:text-inherit lg:shadow-none"
    v-if="authStore.isLoggedIn"
  >
    <div
      class="flex items-start justify-between gap-[20px] overflow-y-auto px-4 pb-2 pt-1"
    >
      <AppMenuItem
        label="Buyers Guide"
        v-if="appStore.plot"
        :to="{
          name: 'buyers-guide',
          params: { plotId: appStore.plot.id ?? 0 },
        }"
        iconName="icon-home"
      />
      <AppMenuItem
        label="Forms"
        v-if="appStore.plot"
        :to="{
          name: 'forms',
          params: { plotId: appStore.plot.id ?? 0 },
        }"
        iconName="icon-form"
      />
      <AppMenuItem
        v-if="false"
        label="Timeline"
        to="/timeline"
        iconName="icon-calendar"
        class="line-through"
      />
      <AppMenuItem
        label="Select Development"
        v-if="userStore.hasMultiplePlots || userStore.isSiteManager"
        :to="{
          name: 'development-select',
        }"
        iconName="icon-settings"
      />
      <AppMenuItem
        label="Logout"
        :to="{ name: 'logout' }"
        iconName="icon-lock"
      />
    </div>
  </div>
</template>
